/* global Float32Array:false */
import * as THREE from 'three'
import DigitalGlitch from './GlitchShader'
import { Pass } from '@johh/three-effectcomposer'

const GlitchPass = function(config) {
  Pass.call(this)

  if (DigitalGlitch === undefined) {
    console.error('THREE.GlitchPass relies on THREE.DigitalGlitch')
  }

  var shader = DigitalGlitch
  this.uniforms = THREE.UniformsUtils.clone(shader.uniforms)
  this.uniforms['tDisp'].value = this.generateHeightmap(
    config.dt_size != undefined ? config.dt_size : 64
  )
  this.frequencyDivider =
    config.frequencyDivider != undefined ? config.frequencyDivider : 5
  this.minXDistortion =
    config.minXDistortion != undefined ? config.minXDistortion : 0
  this.maxXDistortion =
    config.maxXDistortion != undefined ? config.maxXDistortion : 1
  this.minYDistortion =
    config.minYDistortion != undefined ? config.minYDistortion : 0
  this.maxYDistortion =
    config.maxYDistortion != undefined ? config.maxYDistortion : 1
  this.glitchAmountDivider =
    config.glitchAmountDivider != undefined ? config.glitchAmountDivider : 90
  this.minAngle = config.minAngle != undefined ? config.minAngle : -Math.PI
  this.maxAngle = config.maxAngle != undefined ? config.maxAngle : Math.PI
  this.showNoise = config.showNoise != undefined ? config.showNoise : 1
  this.applyGlitch = config.applyGlitch != undefined ? config.applyGlitch : true

  this.material = new THREE.ShaderMaterial({
    uniforms: this.uniforms,
    vertexShader: shader.vertexShader,
    fragmentShader: shader.fragmentShader,
  })

  this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1)
  this.scene = new THREE.Scene()

  this.quad = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), null)
  this.scene.add(this.quad)

  this.goWild = false
  this.curF = 0
  this.generateTrigger()
}

GlitchPass.prototype = Object.assign(Object.create(Pass.prototype), {
  constructor: GlitchPass,

  initUniformValues: function() {
    this.uniforms['amount'].value = Math.random() / this.glitchAmountDivider
    this.uniforms['show_noise'].value = this.showNoise

    this.uniforms['angle'].value = THREE.Math.randFloat(
      this.minAngle,
      this.maxAngle
    )
    this.uniforms['distortion_x'].value = THREE.Math.randFloat(
      this.minXDistortion,
      this.maxXDistortion
    )
    this.uniforms['distortion_y'].value = THREE.Math.randFloat(
      this.minYDistortion,
      this.maxYDistortion
    )
  },

  updateHeightmap: function(dt_size) {
    this.uniforms['tDisp'].value = this.generateHeightmap(dt_size)
  },

  render: function(renderer, writeBuffer, readBuffer) {
    this.uniforms['tDiffuse'].value = readBuffer.texture
    this.uniforms['seed'].value = Math.random() //default seeding
    this.uniforms['byp'].value = 0
    this.uniforms['seed_x'].value = THREE.Math.randFloat(-0.3, 0.3)
    this.uniforms['seed_y'].value = THREE.Math.randFloat(-0.3, 0.3)

    if (this.applyGlitch == false) {
      this.uniforms['byp'].value = 1
    }
    if (this.curF % this.randX == 0 || this.goWild == true) {
      this.curF = 0
      this.initUniformValues()
      this.generateTrigger()
    } else if (this.curF % this.randX < this.randX / this.frequencyDivider) {
      this.initUniformValues()
    } else if (this.goWild == false) {
      this.uniforms['byp'].value = 1
    }
    this.curF++
    this.quad.material = this.material
    if (this.renderToScreen) {
      renderer.render(this.scene, this.camera)
    } else {
      renderer.render(this.scene, this.camera, writeBuffer, this.clear)
    }
  },

  generateTrigger: function() {
    this.randX = THREE.Math.randInt(120, 240)
  },

  generateHeightmap: function(dt_size) {
    var data_arr = new Float32Array(dt_size * dt_size * 3)
    var length = dt_size * dt_size

    for (var i = 0; i < length; i++) {
      var val = THREE.Math.randFloat(0, 1)
      data_arr[i * 3 + 0] = val
      data_arr[i * 3 + 1] = val
      data_arr[i * 3 + 2] = val
    }

    var texture = new THREE.DataTexture(
      data_arr,
      dt_size,
      dt_size,
      THREE.RGBFormat,
      THREE.FloatType
    )
    texture.needsUpdate = true
    return texture
  },
})

export default GlitchPass
