import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.span`
  font-size: 15px;
  width: 65px;
  span {
    display: inline-flex;
  }
`

const Time = () => {
  const [time, setTime] = useState('')

  useEffect(() => {
    let interval

    const checkTime = i => {
      return i < 10 ? '0' + i : i
    }

    const calculateTime = () => {
      const today = new Date()
      // console.log(today.getTimezoneOffset()) // use this to calculate CT specific time
      const h = checkTime(today.getHours())
      const m = checkTime(today.getMinutes())
      const s = checkTime(today.getSeconds())

      setTime(`${h}:${m}:${s}`)
    }

    interval = window.setInterval(() => {
      calculateTime()
    }, 500)

    return () => {
      window.clearInterval(interval)
    }
  }, [])

  return (
    <Container>
      <span>{time}</span>
    </Container>
  )
}

export default Time
