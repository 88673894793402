import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const Section = ({ children, bodyBackgroundColor }) => {
  const containerRef = useRef()

  useEffect(() => {
    const handleScroll = () => {
      const elementRect = containerRef.current.getBoundingClientRect()
      // Magic number 100
      const elementTop = elementRect.top - window.innerHeight + 200

      if (
        elementTop < 0 &&
        elementTop < 0 + elementRect.height &&
        document.body.style.backgroundColor !== bodyBackgroundColor
      ) {
        document.body.style.backgroundColor = bodyBackgroundColor
      } else if (elementTop > 0) {
        document.body.style.backgroundColor = null
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return <section ref={containerRef}>{children}</section>
}

Section.propTypes = {
  bodyBackgroundColor: PropTypes.string,
  children: PropTypes.any,
  entered: PropTypes.bool,
}

export default Section
