import React from 'react'
import styled from 'styled-components'

import { screenSizes, gutter } from '../theme'
import BigLink from '../components/BigLink'
import WorkWithUsGif from '../static/images/gifs/work_with_us.gif'

const FooterContainer = styled.footer`
  padding: 50px ${gutter.mobile}px 30px;
  margin-top: 200px;

  @media (min-width: ${screenSizes.tablet}px) {
    padding: 0px ${gutter.tablet}px 30px;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    padding: 0px ${gutter.desktop}px 30px;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    padding: 0px ${gutter.desktopLarge}px 30px;
  }
`

const Footer = () => (
  <>
    <FooterContainer>
      <BigLink
        to="/contact-us"
        gifurl={WorkWithUsGif}
        alwaysShowGifOnMobile={true}
      >
        Work with us
      </BigLink>
    </FooterContainer>
  </>
)

export default Footer
