import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { gutter, screenSizes, colors } from '../theme'

import Time from './Time'
import ThreeModelView from './ThreeModelView'
import BigLinkBase from './BigLink'
import WeatherWidget from './WeatherWidget'

const Bar = styled.div`
  background-color: #1d1d1d;
  position: absolute;
  height: 3px;
  width: 100%;
  max-width: 24px;
  right: 5px;
  transition: all ease-in-out 300ms;
  transform-origin: 50%;

  &:first-child {
    top: 10px;
  }

  &:last-child {
    top: 17px;
    width: 14px;
  }
`

const MenuIcon = styled.div`
  position: fixed;
  z-index: 40;
  width: 34px;
  left: calc(${gutter.mobile}px + 100px);
  height: 30px;
  transform: translate(0, -50%);
  top: 55px;
  /* mix-blend-mode: difference; */
  /* left: calc(${gutter.mobile}px); */
  margin-left: -0.4vw;

  @media (min-width: ${screenSizes.tablet}px) {
    left: calc(${gutter.tablet}px + 100px);
  }

  @media (min-width: ${screenSizes.desktop}px) {
    left: calc(${gutter.desktop}px + 200px);
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    left: calc(${gutter.desktopLarge}px + 200px);
  }
  ${props =>
    props &&
    props.active &&
    `
    ${Bar} {
      top: 12px;
      width: 100%;

      &:first-child {
        transform: rotate(-45deg);
      }

      &:last-child {
        transform: rotate(45deg);
      }
    }
  `}

  &:hover {
    ${Bar} {
      width: 100%;
    }
  }
`

const TimeContainer = styled.div`
  position: fixed;
  top: 40px;
  right: ${gutter.mobile}px;
  font-size: 13px;

  @media (min-width: ${screenSizes.tablet}px) {
    right: calc(${gutter.tablet}px);
  }

  @media (min-width: ${screenSizes.desktop}px) {
    right: calc(${gutter.desktop}px);
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    right: calc(${gutter.desktopLarge}px);
  }
`

const MenuContainer = styled.div`
  display: flex;
  z-index: 29;
  flex-direction: column;
  padding: 60px calc(${gutter.mobile}px);
  padding-top: 190px;
  justify-content: center;
  position: fixed;
  opacity: 0;
  transition: opacity ease 1000ms;
  min-height: 100vh;
  width: 100vw;
  pointer-events: none;

  @media (min-width: ${screenSizes.tablet}px) {
    padding: 60px calc(${gutter.tablet}px);
  }

  @media (min-width: ${screenSizes.desktop}px) {
    padding: 60px calc(${gutter.desktop}px + 200px);
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    padding: 60px calc(${gutter.desktopLarge}px + 200px);
  }

  ${props =>
    props.active &&
    css`
      pointer-events: all;
      opacity: 1;
      background-color: ${colors.primary};
    `}
`

const ThreeModelContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  max-width: 2048px;
  max-height: 1080px;
  z-index: -1;
  width: 100%;
  height: 100%;
`

const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  a {
    margin-right: 60px;
    text-transform: uppercase;
  }
  margin-top: 30px;

  @media (min-width: ${screenSizes.tablet}px) {
    flex-direction: row;
  }
`

const BigLink = styled(BigLinkBase)`
  margin-top: -6px;

  h3 {
    font-size: 20pt;
    margin-top: 20px;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    h3 {
      font-size: 70pt;
      margin-top: 20px;
    }
  }
  @media (min-width: ${screenSizes.desktopLarge}px) {
    h3 {
      font-size: 10vh;
      margin-top: 20px;
    }
  }
`

export default class Menu extends React.PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    toggleMenu: PropTypes.func,
    navigateTo: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      weather: {},
    }

    this.timeout = null
    this.handleLinkClick = this.handleLinkClick.bind(this)
  }

  async componentDidMount() {
    this.model.stop()
    const weather = await fetch('/weather.json').then(result => result.json())
    this.setState({ weather: weather.list })
  }

  componentDidUpdate() {
    if (this.props.toggleMenu) {
      this.model.start()
    } else {
      this.model.stop()
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
  }

  handleLinkClick() {
    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(() => {
      this.props.toggleMenu()
    }, 500)
  }

  render() {
    const { active, toggleMenu } = this.props
    return (
      <>
        <MenuIcon className="menu-icon" active={active} onClick={toggleMenu}>
          <Bar />
          <Bar />
        </MenuIcon>

        <MenuContainer active={active}>
          <BigLink onClick={this.handleLinkClick} to={'/#work'}>
            Work
          </BigLink>
          <BigLink onClick={this.handleLinkClick} to={'/about/'}>
            About
          </BigLink>
          <BigLink onClick={this.handleLinkClick} to={'/contact-us/'}>
            Contact
          </BigLink>

          <SocialContainer>
            <a
              href="https://www.instagram.com/teamgeek.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://github.com/teamgeek-io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </SocialContainer>

          <ThreeModelContainer>
            <ThreeModelView
              applyGlitch={true}
              ref={model => (this.model = model)}
              src="/models/bolt.glb"
              rotationSpeed={{ x: 0.6, y: 0.5 }}
              meshIndex={0}
              cameraPos={{
                x: 0,
                y: 0,
                z: 18,
              }}
              cameraPosResponsive={{
                [screenSizes.desktop]: {
                  x: 2,
                  y: 0,
                  z: 15,
                },
              }}
              rotationOffset={{
                x: 0.1,
                y: -0.4,
              }}
            />
          </ThreeModelContainer>
        </MenuContainer>

        <TimeContainer>
          <span>CPT </span>
          <WeatherWidget
            weather={this.state.weather}
            city={'Cape Town'}
            plain
          />{' '}
          <span> / </span>
          <Time />
        </TimeContainer>
      </>
    )
  }
}
