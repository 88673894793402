import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { gutter, screenSizes } from '../theme'
import Logo from '../static/images/logo.svg'
import SectionIndicator from './SectionIndicator'

const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  display: grid;
  z-index: 30;
  grid-column-gap: 15px;
  grid-template-columns: 80px 1fr 24px;
  padding: 40px ${gutter.mobile}px;

  img {
    margin-bottom: 0;
  }

  .menu-icon {
    display: flex;
    align-items: center;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    padding: 40px ${gutter.tablet}px;
    grid-column-gap: 30px;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    padding: 40px ${gutter.desktop}px;
    grid-template-columns: 80px 1fr 24px;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    padding: 40px ${gutter.desktopLarge}px;
  }
`

const Title = styled(SectionIndicator)`
  display: block;
  color: white;
  margin-bottom: 0;
  text-align: right;
  margin-top: 25px;
  padding-right: 30px;

  @media (min-width: ${screenSizes.tablet}px) {
    padding-right: 30px;
  }
  @media (max-width: ${screenSizes.desktop}px) and (orientation: portrait) {
    /* display: none; */
  }
`

const TitleContainer = styled.div`
  max-width: 600px;
  margin-left: auto;

  @media (min-width: ${screenSizes.desktopExtraLarge}px) {
    max-width: none;
  }
`

class Header extends Component {
  static propTypes = {
    title: PropTypes.string,
    toggleMenu: PropTypes.func,
  }

  render() {
    const { title, titleLink } = this.props

    return (
      <>
        <HeaderContainer>
          <Link className="menu-icon" to="/">
            <img src={Logo} alt="Teamgeek" />
          </Link>
          <TitleContainer>
            {title && titleLink ? (
              <a href={titleLink} target="_blank" rel="noopener noreferrer">
                <Title>{title}</Title>
              </a>
            ) : title ? (
              <Title>{title}</Title>
            ) : null}
          </TitleContainer>
        </HeaderContainer>
      </>
    )
  }
}

Header.propTypes = {
  title: PropTypes.string,
  titleLink: PropTypes.string,
}

Header.defaultProps = {
  title: ``,
}

export default Header
