import React from 'react'
import styled from 'styled-components'
import { TweenLite } from 'gsap'
import { colors } from '../theme'

import DragIcon from '../static/images/drag_1.svg'
import DragIcon2 from '../static/images/drag_1.svg'
import DragIcon3 from '../static/images/drag_1.svg'

const CursorContainer = styled.div`
  position: fixed;
  display: block;
  z-index: 100;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  mix-blend-mode: difference;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: width ease 500ms, height ease 500ms, border ease 500ms,
    background-color ease 250ms;
  overflow: hidden;
  top: -100px;
  left: -100px;
  background-color: ${colors.primary};

  &.show-swiper-icon {
    background-color: transparent;
    width: 70px !important;
    height: 70px !important;
    border: 1px transparent solid;

    .drag-icon-1 {
      opacity: 1;
    }

    &.show-swiper-clicked-icon {
      .drag-icon-1 {
        opacity: 0;
      }

      .drag-icon-2 {
        opacity: 1;
      }
    }

    &.show-swiper-clicked-icon-2 {
      .drag-icon-1 {
        opacity: 0;
      }

      .drag-icon-3 {
        opacity: 1;
      }
    }
  }
`

const DragIconImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity ease 250ms;
`

class Cursor extends React.PureComponent {
  constructor(props) {
    super(props)

    this.timeout = null
    this.onMouseMove = this.onMouseMove.bind(this)
    this.moveCursor = this.moveCursor.bind(this)
    this.onWorkSwiperMouseDown = this.onWorkSwiperMouseDown.bind(this)
    this.onWorkSwiperMouseUp = this.onWorkSwiperMouseUp.bind(this)
    this.onSwiperMouseDown = this.onSwiperMouseDown.bind(this)
    this.onSwiperMouseUp = this.onSwiperMouseUp.bind(this)
    this.onSwiperEnter = this.onSwiperEnter.bind(this)
    this.onSwiperLeave = this.onSwiperLeave.bind(this)
    this.onLinkIconEnter = this.onLinkIconEnter.bind(this)
    this.onLinkIconLeave = this.onLinkIconLeave.bind(this)
    this.onLinkEnter = this.onLinkEnter.bind(this)
    this.onLinkLeave = this.onLinkLeave.bind(this)
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
    window.removeEventListener('mousemove', this.onMouseMove)
    window.cancelAnimationFrame(this.frameId)

    const swiperContainers = document.querySelectorAll(
      '.swiper-container:not(.swiper-container-work)'
    )
    Array.from(swiperContainers).forEach(el => {
      el.removeEventListener('mousedown', this.onSwiperMouseDown)
      el.removeEventListener('mouseup', this.onSwiperMouseUp)
      el.removeEventListener('mouseenter', this.onSwiperEnter)
      el.removeEventListener('mouseleave', this.onSwiperLeave)
    })

    const swiperContainerWork = document.getElementsByClassName(
      'swiper-container-work'
    )
    Array.from(swiperContainerWork).forEach(el => {
      el.removeEventListener('mousedown', this.onWorkSwiperMouseDown)
      el.removeEventListener('mouseup', this.onWorkSwiperMouseUp)
      el.removeEventListener('mouseenter', this.onSwiperEnter)
      el.removeEventListener('mouseleave', this.onSwiperLeave)
    })

    Array.from(document.getElementsByClassName('menu-icon')).forEach(el => {
      el.removeEventListener('mouseenter', this.onLinkIconEnter)
      el.removeEventListener('mouseleave', this.onLinkIconLeave)
    })

    Array.from(
      document.querySelectorAll('a:not(.menu-icon), .swiper-pagination-bullet')
    ).forEach(el => {
      el.removeEventListener('mouseenter', this.onLinkEnter)
      el.removeEventListener('mouseleave', this.onLinkLeave)
    })
  }

  componentDidMount() {
    this.setState({
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    })
    window.addEventListener('mousemove', this.onMouseMove)

    const swiperContainers = document.querySelectorAll(
      '.swiper-container:not(.swiper-container-work):not(.swiper-container-text)'
    )

    Array.from(swiperContainers).forEach(el => {
      el.addEventListener('mousedown', this.onSwiperMouseDown)
      el.addEventListener('mouseup', this.onSwiperMouseUp)
      el.addEventListener('mouseenter', this.onSwiperEnter)
      el.addEventListener('mouseleave', this.onSwiperLeave)
    })

    const swiperContainerWork = document.getElementsByClassName(
      'swiper-container-work'
    )
    Array.from(swiperContainerWork).forEach(el => {
      el.addEventListener('mousedown', this.onWorkSwiperMouseDown)
      el.addEventListener('mouseup', this.onWorkSwiperMouseUp)
      el.addEventListener('mouseenter', this.onSwiperEnter)
      el.addEventListener('mouseleave', this.onSwiperLeave)
    })

    Array.from(document.getElementsByClassName('menu-icon')).forEach(el => {
      el.addEventListener('mouseenter', this.onLinkIconEnter)
      el.addEventListener('mouseleave', this.onLinkIconLeave)
    })

    this.timeout = window.setTimeout(() => {
      Array.from(
        document.querySelectorAll(
          'a:not(.menu-icon), .swiper-pagination-bullet'
        )
      ).forEach(el => {
        el.addEventListener('mouseenter', this.onLinkEnter)
        el.addEventListener('mouseleave', this.onLinkLeave)
      })
    })
  }

  onMouseMove(e) {
    this.clientX = e.clientX
    this.clientY = e.clientY
    window.requestAnimationFrame(this.moveCursor)
  }

  moveCursor() {
    TweenLite.set(this.cursor, {
      left: this.clientX,
      top: this.clientY,
    })
  }

  onWorkSwiperMouseDown() {
    this.cursor.classList.add('show-swiper-clicked-icon-2')
  }

  onWorkSwiperMouseUp() {
    this.cursor.classList.remove('show-swiper-clicked-icon-2')
  }

  onSwiperMouseDown() {
    this.cursor.classList.add('show-swiper-clicked-icon')
  }

  onSwiperMouseUp() {
    this.cursor.classList.remove('show-swiper-clicked-icon')
    this.cursor.classList.remove('show-swiper-clicked-icon-2')
  }

  onSwiperEnter() {
    this.cursor.classList.add('show-swiper-icon')
  }

  onSwiperLeave() {
    this.cursor.classList.remove('show-swiper-icon')
  }

  onLinkIconEnter(e) {
    const linkDimensions = e.target.getBoundingClientRect()
    const size = Math.max(linkDimensions.width, linkDimensions.height) + 20
    TweenLite.to(this.cursor, 0.25, {
      left: linkDimensions.x + linkDimensions.width / 2,
      top: linkDimensions.y + linkDimensions.height / 2,
      width: size,
      height: size,
    })
    window.removeEventListener('mousemove', this.onMouseMove)
  }

  onLinkIconLeave() {
    TweenLite.to(this.cursor, 0.25, {
      left: this.clientX,
      top: this.clientY,
      width: 15,
      height: 15,
    })
    window.addEventListener('mousemove', this.onMouseMove)
  }

  onLinkEnter() {
    TweenLite.to(this.cursor, 0.25, {
      width: 30,
      height: 30,
    })
  }

  onLinkLeave() {
    TweenLite.to(this.cursor, 0.25, {
      left: this.clientX,
      top: this.clientY,
      width: 15,
      height: 15,
    })
  }

  render() {
    return (
      <CursorContainer ref={cursor => (this.cursor = cursor)} id="cursor">
        <DragIconImg className="drag-icon-1" src={DragIcon} alt="Teamgeek" />
        <DragIconImg className="drag-icon-2" src={DragIcon2} alt="Teamgeek" />
        <DragIconImg className="drag-icon-3" src={DragIcon3} alt="Teamgeek" />
      </CursorContainer>
    )
  }
}

export default Cursor
