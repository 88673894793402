import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { screenSizes } from '../theme'
import PropTypes from 'prop-types'

const Gif = styled.div`
  opacity: ${({ alwaysShowGifOnMobile }) =>
    alwaysShowGifOnMobile ? '1' : '0'};
  transition: all ease 500ms;
  top: 0;
  left: calc(50% - 50px);
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${screenSizes.tablet}px) {
    opacity: 0;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    font-size: 70pt;
    position: absolute;
    height: 500px;
    background-size: contain;
  }
`

const Container = styled.div`
  ${props =>
    props.hasGif
      ? css`
          position: relative;

          @media (min-width: ${screenSizes.desktop}px) {
            padding-top: 200px;
            min-height: 500px;
          }
        `
      : css`
          ${Gif} {
            display: none;
          }
        `}
`

const Bar = styled.div`
  position: absolute;
  width: 100%;
  height: 4%;
  left: 0;
  bottom: 0;
  transition: 500ms height;
  background-color: white;
  z-index: 1;
`

const BigTextContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  &:hover {
    ${Bar} {
      height: 100%;
    }

    + ${Gif} {
      opacity: 1;
      background-image: url(${props => props.gifurl});
    }
  }

  ${props =>
    props.alwaysShowGifOnMobile &&
    css`
      @media (max-width: ${screenSizes.tablet + 1}px) {
        + ${Gif} {
          opacity: 1;
          background-image: url(${props => props.gifurl});
        }
      }
    `}
`

const Text = styled.h3`
  z-index: 2;
  position: relative;
  color: white;
  font-size: 60pt;
  mix-blend-mode: difference;
  @media (min-width: ${screenSizes.tablet}px) {
    font-size: 70pt;
  }
  @media (min-width: ${screenSizes.desktopLarge}px) {
    font-size: 100pt;
  }
`

export default class BigLink extends React.PureComponent {
  static propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    children: PropTypes.any,
    gifurl: PropTypes.string,
    className: PropTypes.string,
    alwaysShowGifOnMobile: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.renderLinkContents = this.renderLinkContents.bind(this)
  }

  renderLinkContents() {
    return (
      <>
        <BigTextContainer
          gifurl={this.props.gifurl}
          alwaysShowGifOnMobile={this.props.alwaysShowGifOnMobile}
        >
          <Bar />
          <Text>{this.props.children}</Text>
        </BigTextContainer>
        {this.props.gifurl && <Gif />}
      </>
    )
  }

  render() {
    return (
      <Container hasGif={!!this.props.gifurl} className={this.props.className}>
        {this.props.href ? (
          <a {...this.props}>{this.renderLinkContents()}</a>
        ) : (
          <Link {...this.props} to={this.props.to}>
            {this.renderLinkContents()}
          </Link>
        )}
      </Container>
    )
  }
}
