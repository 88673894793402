import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { screenSizes } from '../theme'

const Text = styled.span`
  font-size: 7pt;
  text-transform: uppercase;

  @media (min-width: ${screenSizes.tablet}px) {
    font-size: 7pt;
  }
`

const SectionIndicator = ({ children, className }) => (
  <Text className={className}>/&nbsp;&nbsp;{children}</Text>
)

SectionIndicator.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export default SectionIndicator
