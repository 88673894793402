import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TweenLite } from 'gsap'
import { screenSizes, gutter, glitchTransitionDuration } from '../theme'

const SmoothScrollContainer = styled.div``

const ProgressContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  overflow: hidden;
  top: 30px;
  left: 0;
  max-height: 80%;
  opacity: 0;
  height: 300px;
  z-index: 2;
  width: 20px;

  @media (min-width: ${screenSizes.tablet}px) {
    width: ${gutter.tablet}px;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    width: ${gutter.desktop}px;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    width: ${gutter.desktopLarge}px;
  }
`

const ProgressBar = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  width: 1px;
  height: 100%;
`

const ProgressBarFill = styled.div`
  background-color: white;
  width: 1px;
  height: 100%;
`

export default class SmoothScroll extends React.PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    children: PropTypes.any,
  }

  static defaultProps = {
    active: true,
  }

  constructor(props) {
    super(props)

    this.state = {
      active: props.active,
    }

    /* smooth scroll variables and functions */
    this.timeout = false
    this.disableSmoothScroll = false
    this.requestId = null
    this.scroller = null
    this.container = null
    this.updateScroller = this.updateScroller.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.onWheel = this.onWheel.bind(this)
    this.onResize = this.onResize.bind(this)

    /* progress variables and functions */
    this.progressOffsetY = null
    this.progressHeight = null
    this.initProgress = this.initProgress.bind(this)
    this.updateProgress = this.updateProgress.bind(this)
    this.initGlobalVariables = this.initGlobalVariables.bind(this)
  }

  componentDidMount() {
    this.timeout = window.setTimeout(() => {
      /* disable smooth scroll on tablet and mobile devices */
      this.width = window.innerWidth
      this.disableSmoothScroll = this.width < screenSizes.desktop

      this.initGlobalVariables()

      if (this.progressContainerEl !== null) {
        this.initProgress()
      }

      this.scroller = {
        target: this.container,
        ease: 0.05,
        y: window.pageYOffset,
        targetY: window.pageYOffset,
        scrollRequest: 0,
      }
      window.addEventListener('scroll', this.onScroll)

      if (this.state.active) {
        this.updateScroller()
        window.addEventListener('resize', this.onResize)
        if (!this.disableSmoothScroll) {
          window.addEventListener('wheel', this.onWheel)
        }
      }
    }, glitchTransitionDuration)
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
    if (!this.disableSmoothScroll) {
      window.removeEventListener('wheel', this.onWheel)
    }
    if (this.requestId) {
      window.cancelAnimationFrame(this.requestId)
    }
  }

  initProgress() {
    this.progressContainerTween = {
      target: this.progressContainer,
      opacity: 0,
    }

    this.progressBarTween = {
      target: this.progressBar,
      y: -100,
      ease: 0.1,
    }

    TweenLite.set(this.progressBarTween.target, {
      y: `${this.progressBarTween.y}%`,
    })
  }

  updateProgress() {
    if (this.scroller.y > this.progressOffsetY) {
      this.progressContainerTween.opacity = 1

      TweenLite.to(this.progressContainerTween.target, 0.5, {
        opacity: 1,
      })

      this.progressBarTween.y =
        -100 +
        ((this.scroller.y - this.progressOffsetY) /
          (this.progressHeight - this.progressOffsetY)) *
          100

      if (this.progressBarTween.y < 0) {
        TweenLite.set(this.progressBarTween.target, {
          y: `${this.progressBarTween.y}%`,
          ease: this.progressBarTween.ease,
        })
      } else {
        TweenLite.set(this.progressBarTween.target, {
          y: `0%`,
          ease: this.progressBarTween.ease,
        })
      }
    } else {
      TweenLite.to(this.progressContainerTween.target, 0.5, {
        opacity: 0,
      })
    }
  }

  initGlobalVariables() {
    const height = this.container.clientHeight
    const windowHeight = window.innerHeight
    this.progressContainerEl = document.getElementById('progress-container')
    this.scrollHeight = height - windowHeight

    if (this.progressContainerEl !== null) {
      const dimensions = this.progressContainerEl.getBoundingClientRect()
      this.progressOffsetY = window.pageYOffset + dimensions.y
      this.progressHeight = dimensions.height
      this.windowHeight = window.innerHeight
    }
  }

  onScroll() {
    this.scroller.y = window.pageYOffset

    if (this.progressContainerEl !== null) {
      this.updateProgress()
    }
  }

  onWheel(e) {
    e.preventDefault()
    if (this.scroller.scrollRequest == 0) {
      this.scroller.targetY = window.pageYOffset
      this.scroller.y = window.pageYOffset
    }

    this.scroller.targetY += e.deltaY
    this.scroller.targetY = Math.max(
      Math.min(this.scroller.targetY, this.scrollHeight),
      0
    )
    this.scroller.scrollRequest++
    if (!this.requestId) {
      this.requestId = window.requestAnimationFrame(this.updateScroller)
    }
  }

  onResize() {
    this.initGlobalVariables()
  }

  updateScroller() {
    this.scroller.y +=
      (this.scroller.targetY - this.scroller.y) * this.scroller.ease

    if (
      Math.abs(this.scroller.targetY - this.scroller.y) < 1 ||
      this.scroller.y > this.scrollHeight - 0.05
    ) {
      this.scroller.y = this.scroller.targetY
      this.scroller.scrollRequest = 0
    }

    window.scrollTo({
      top: this.scroller.y,
    })

    this.requestId =
      this.scroller.scrollRequest > 0
        ? window.requestAnimationFrame(this.updateScroller)
        : null
  }

  render() {
    return (
      <>
        <SmoothScrollContainer
          ref={ref => (this.container = ref)}
          active={this.state.active}
        >
          {this.props.children}
        </SmoothScrollContainer>
        <ProgressContainer ref={ref => (this.progressContainer = ref)}>
          <ProgressBar>
            <ProgressBarFill ref={ref => (this.progressBar = ref)} />
          </ProgressBar>
        </ProgressContainer>
      </>
    )
  }
}
