import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'

import { glitchTransitionDuration } from '../theme'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import SmoothScroll from './SmoothScroll'
import Cursor from './CustomCursor'
import Section from './Section'

const ANIMATE_OUT_TIMING = 0
const ANIMATE_IN_TIMING = glitchTransitionDuration

const Container = styled.div`
  overflow: hidden;
`

const Body = styled.div`
  transition: opacity ease 300ms;

  ${props =>
    props.blur &&
    `
    opacity: 0;
    @supports (-moz-appearance:none) {
      & { filter: none; }
    }
  `}
`

class Layout extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      menuActive: false,
      isSafari: false,
      showCursor: false,
    }

    this.frameId = null
    this.toggleMenu = this.toggleMenu.bind(this)
    this.navigateTo = this.navigateTo.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)
    this.renderContent = this.renderContent.bind(this)
  }

  async componentDidMount() {
    if (window.innerWidth > 768) {
      this.setState({
        showCursor: true,
      })
    }
    await new Promise(resolve => window.setTimeout(resolve, 500))
    // eslint-disable-next-line no-console
    console.log(
      `%c

-------------------------------------------------------------------------------------------
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠛⣀⢄⠔⠀⢀⡤⣕⣪⣴⠟⠋⠡⢁⣢⡤⣀⣤⣴⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠟⡡⡮⠋⣁⠔⣢⡾⠟⢛⣩⡤⣔⡶⣛⣝⣵⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣏⠉⠉⠉⠋⠉⠉⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠏⠀⢀⣤⠊⣱⣾⡿⢃⣼⣟⡭⢞⣡⣾⣿⣻⣿⣿⣿⣿⣿⣿⡿⠿⠛⠛⠛⠋⡛⣿⣿⣿⣿⣤⡀⠘⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡏⠀⢠⠞⢡⣾⣿⢋⣴⣿⠟⣡⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣓⢐⢢⢄⡀⠀⠀⠀⠈⠻⣿⣿⣿⣿⡀⠘⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠀⣠⠋⣰⣿⣟⣵⡞⢋⣴⣾⣿⣿⡿⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠷⠖⠀⠀⠀⠀⠻⣿⣿⣿⣿⡄⠈⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇⠐⠅⣴⣿⠿⠡⢂⣾⣿⠿⣻⣿⢫⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡟⣿⣟⡥⠃⠀⠀⠀⠀⠀⠙⣿⣿⣿⡇⠀⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠁⢠⢠⣿⠃⠂⣰⣿⡿⢋⣾⣿⢱⣽⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣧⣿⣿⠃⠀⠀⠀⠀⠀⠀⠀⢿⣿⣿⣧⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡟⢠⣎⠆⠡⠂⣴⣿⡿⢁⣾⣿⣫⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⢏⣿⣋⡄⡀⠀⠀⠀⠀⠀⠀⠘⣿⣿⣿⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠁⠌⠀⠠⠁⣼⣿⡟⢠⣿⣿⣷⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣯⣿⣿⡾⡟⠫⠁⠀⠀⠀⠀⠀⠀⠀⠀⡅⢹⣿⡀⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣏⠀⢘⠀⠀⡼⣿⡟⠀⣼⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡑⠛⠩⠃⠚⠛⡣⠀⠐⢨⣤⣤⣤⣠⠤⠀⠀⠀⡆⢸⣿⠁⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠀⣯⠀⠀⢀⠟⠃⢰⡇⠜⠁⢻⣿⣿⡟⡿⠿⡿⠿⢿⣷⢎⠀⠀⠀⠀⠀⠀⠀⠈⠿⠛⠈⠈⠉⠉⠀⠀⠇⠸⡟⣰⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠀⣈⠶⠂⠈⢘⡔⡐⠀⠀⣀⣀⣿⣷⣿⣶⣿⣿⣷⣶⣔⣯⣉⣦⣶⣀⠀⠀⣶⣶⠻⣿⣿⠛⢦⠦⠀⠀⠀⠀⢨⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⢰⣋⣇⠀⠀⠈⢮⠁⠀⢠⢿⣿⣏⡿⣿⣜⣯⣽⣿⣿⣿⣿⣿⣿⣿⣿⡄⠀⢻⣿⣶⡵⡾⠵⠃⠀⠀⠀⠀⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡎⢦⢻⡄⠀⠀⠐⠀⠀⢸⣿⣿⣿⣿⣿⣻⣽⣽⣿⣿⣿⣿⣿⣿⣿⣿⡇⠀⠀⠹⠗⣯⠄⠀⠀⠀⠀⠀⠀⠀⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡞⠈⣁⡄⠀⠀⠀⠀⠀⢹⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇⠀⠀⠀⢀⠀⠐⠐⡀⠀⠀⠀⠀⠀⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣇⠸⡿⢶⠀⠀⠀⠀⠀⡸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡗⠀⠀⠀⠀⠀⠀⠈⠄⠀⠀⠀⠀⢰⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡘⡆⠫⢱⠀⠀⠀⠀⠁⠰⣹⣿⣿⣿⣿⣿⣿⢿⣿⡿⢟⣛⡻⠉⠀⠀⣀⡀⠁⠀⠀⠀⠀⠀⠀⠀⠀⠘⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇⢶⣿⣿⠀⠀⠀⠀⠀⢠⠶⡯⣿⡯⡿⢳⢚⣾⣿⣿⣿⣿⣿⣡⣴⣯⣮⡀⠀⠀⠂⠀⠀⠀⠀⠀⠀⣠⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣟⠈⠛⠓⡁⠀⠀⠀⠀⠸⡎⢭⣻⣥⢒⣽⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇⠄⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⣦⠀⣱⠀⠀⠀⠀⢙⠿⣾⢧⢽⣿⡿⢿⡿⡻⣿⣿⣿⢿⠿⠿⠟⠿⠇⠀⠀⠀⠀⠀⠀⠀⠀⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇⠘⡇⠀⠀⠂⠈⠔⠎⠟⠉⡝⢁⢸⢠⣶⣾⣿⣶⣾⣿⣛⣛⣷⣿⡿⡲⡀⠀⠀⠀⠀⢰⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⣤⠀⠀⠀⠀⠀⡄⠆⠀⠸⢠⣾⣿⣿⣿⢿⠿⠿⠿⣿⠿⠟⠉⠁⢰⢁⡆⠀⠀⠀⠀⣼⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇⠀⠐⡀⠀⠀⠁⠀⣸⣾⣿⣿⣿⣿⣾⣿⣿⠗⣴⣄⡀⠀⠀⠰⣾⡇⠄⠀⠀⡈⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡟⠀⠀⠀⠐⣀⠠⠀⢠⡿⣿⣿⣿⣿⣿⣿⣿⣣⣾⢿⣿⡧⠄⠠⣄⣙⣿⡆⠀⢠⣶⠸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠓⠀⢸⢠⡀⠈⢻⣂⠀⠈⠹⢻⢻⣿⣿⣿⣿⣿⣿⣯⣽⡺⡇⢀⠀⠸⢾⣘⠀⠀⢸⣿⠀⠙⢻⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠿⠿⠛⠉⠁⠀⠈⠍⠀⠀⠀⠘⣎⢿⣦⡬⢋⡀⠀⠀⠈⠘⣿⣿⣿⣿⣿⣿⣿⣼⣿⡄⣿⠇⡄⡜⠃⠂⢰⣬⡏⠀⠀⠀⠹⠿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⡿⠿⠿⠟⠛⠉⠁⠀⠀⠀⠀⠀⠀⠀⢀⣷⠀⡀⠀⠀⣿⣿⣿⣿⣿⣿⠇⠀⠀⠀⢹⢻⣿⣿⣿⣿⣿⢿⡅⢃⣾⡀⢡⠉⢐⣤⣺⣿⠗⠃⠀⠀⠀⠀⡌⠉⠙⠛⠛⠛⠿⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⠛⠉⠁⠀⠀⠀⠀⠀⠀⠀⠀⠠⢈⡆⡄⢈⠁⣸⡯⠀⡅⠀⢠⢻⣿⣿⣿⣿⣿⣾⡔⣀⠀⠈⠙⢛⢹⣿⣿⣧⡌⠸⡤⣼⡧⣸⣡⣾⣿⣿⡟⠁⠀⠀⠀⠀⠀⡌⠁⠀⠀⢀⠀⠀⠀⠈⠉⠛⠛⠿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⠀⠀⢀⠀⠀⠀⠁⠘⠀⠀⠇⠲⣼⣦⣭⠈⠞⢴⣿⠀⠋⠀⢨⣾⡿⣿⣿⣿⣿⣿⣿⣿⣴⠴⣀⠀⠀⠀⠸⠇⣇⣀⢱⣿⣷⣿⣿⣿⣿⣟⠃⠀⠀⠀⠀⠀⣴⡿⢠⠃⠐⠈⠀⠀⡀⠀⠀⠀⠀⠀⠀⠙⠻⣿⣿⣿⣿⣿⣿⣿
⢸⠄⢲⣾⣧⢶⡇⣗⣀⠀⢇⢴⣿⣿⡧⠀⣣⣼⣿⢄⢨⠰⣾⣿⣷⣿⣿⣿⣿⣿⣿⣿⣿⣿⣥⣜⢐⣴⣴⣬⣿⣿⣿⣿⣿⣿⣿⣿⡏⠁⠀⠀⠀⠀⠀⢸⣿⠃⡴⠀⡆⠀⡇⠐⠁⠁⠶⠀⠀⠀⠀⠠⠀⠀⠉⢻⣿⣿⣿⣿
⣤⣶⣿⣿⣿⣿⣧⣧⣴⣼⣿⣿⣿⣿⣦⡜⣿⢻⣿⡆⣼⣶⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡟⣠⡃⠀⠀⠀⠀⢀⣾⣿⢐⠃⢀⠇⡸⢠⠆⣀⠂⣶⢰⢢⠃⠀⠂⠆⠀⠀⠀⢻⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣣⣿⣸⣿⣇⣻⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡯⣡⠋⠀⠀⠀⠀⠀⣸⡿⣹⢸⠀⠈⠠⠃⡬⢰⣾⣺⣧⠂⡞⡰⠀⢰⠁⣰⢀⠄⠈⢿⣿⣿
-------------------------------------------------------------------------------------------
       _    _ ______ _      _      ____       _______ _    _ ______ _____  ______
      | |  | |  ____| |    | |    / __ \\     |__   __| |  | |  ____|  __ \\|  ____|
      | |__| | |__  | |    | |   | |  | |       | |  | |__| | |__  | |__) | |__
      |  __  |  __| | |    | |   | |  | |       | |  |  __  |  __| |  _  /|  __|
      | |  | | |____| |____| |___| |__| |       | |  | |  | | |____| | \\ \\| |____
      |_|  |_|______|______|______\\____/        |_|  |_|  |_|______|_|  \\_\\______|

-------------------------------------------------------------------------------------------`,
      'font-family:monospace; color: white; background-color: black;'
    )
    // eslint-disable-next-line no-console
    console.log(
      `%cIf you are a bold one like Obi-Wan, drop us a mail at work@teamgeek.io`,
      'font-family:monospace; color: white; background-color: black; font-size: 16pt;'
    )
  }

  onImageLoaded() {
    this.setState({ navigateIn: true }, () => {
      document.body.style.overflowY = 'scroll'

      window.setTimeout(() => {
        this.setState(
          {
            navigateInDone: true,
          },
          () => {
            if (window.location.hash) {
              try {
                const element = document.getElementById(
                  window.location.hash.replace('#', '')
                )
                element.scrollIntoView(false)
              } catch (err) {
                console.error(err)
              }
            }
          }
        )
      }, ANIMATE_IN_TIMING)
    })
  }

  toggleMenu() {
    if (this.state.menuActive) {
      document.body.style.overflowY = 'scroll'
    } else {
      document.body.style.overflowY = 'hidden'
    }
    this.props.onMenuActive(!this.state.menuActive)
    this.setState({ menuActive: !this.state.menuActive })
  }

  navigateTo(page) {
    if (window.location.pathname !== page) {
      this.setState({ navigateOut: true }, () => {
        window.setTimeout(() => {
          navigate(page)
        }, ANIMATE_OUT_TIMING)
      })
    }
  }

  renderContent() {
    const {
      headerTitle,
      headerLink,
      children,
      hideFooter,
      smoothScroll,
    } = this.props
    const { menuActive, navigateInDone, isSafari, showCursor } = this.state

    return (
      <>
        <>
          {showCursor && <Cursor ref={cursor => (this.cursor = cursor)} />}
          <Menu active={menuActive} toggleMenu={this.toggleMenu} />
        </>
        <Container show={navigateInDone}>
          <SmoothScroll active={isSafari ? false : smoothScroll}>
            <Header title={headerTitle} titleLink={headerLink} />
            <Body blur={menuActive}>{children}</Body>
            {hideFooter !== true && (
              <Section bodyBackgroundColor={'#1d1d1d'}>
                <Footer />
              </Section>
            )}
          </SmoothScroll>
        </Container>
      </>
    )
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={this.renderContent}
      />
    )
  }
}

Layout.propTypes = {
  hideFooter: PropTypes.bool,
  children: PropTypes.node.isRequired,
  headerTitle: PropTypes.string,
  headerLink: PropTypes.string,
  initialImage: PropTypes.string,
  smoothScroll: PropTypes.bool,
  onMenuActive: PropTypes.func,
}

Layout.defaultProps = {
  smoothScroll: false,
  onMenuActive: () => {},
}

export default Layout

export const query = graphql`
  fragment GatsbyImage on File {
    childImageSharp {
      fluid(maxWidth: 1400, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
