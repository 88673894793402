import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'

import SunnyIcon from '../static/images/weather/01d.png'
import PartlyCloudyIcon from '../static/images/weather/02d.png'
import CloudyIcon from '../static/images/weather/03d.png'
import BrokenCloudsIcon from '../static/images/weather/04d.png'
import RainIcon from '../static/images/weather/09d.png'
import LightRainIcon from '../static/images/weather/10d.png'
import ThunderStormIcon from '../static/images/weather/11d.png'
import SnowIcon from '../static/images/weather/13d.png'
import FogIcon from '../static/images/weather/50d.png'

const Container = styled.div`
  display: inline-block;
  padding-left: ${({ plain }) => (plain ? 0 : 26)}px;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 300ms;
  span {
    font-size: ${({ plain }) => (plain ? 15 : 21)}px;
  }
  img {
    margin: 0 8px -2px;
  }
`

const ICONS = {
  '01d': SunnyIcon,
  '02d': PartlyCloudyIcon,
  '03d': CloudyIcon,
  '04d': BrokenCloudsIcon,
  '09d': RainIcon,
  '10d': LightRainIcon,
  '11d': ThunderStormIcon,
  '13d': SnowIcon,
  '50d': FogIcon,
}

const Icon = styled.img`
  width: 24px;
`

const mapWeather = weather => {
  const icon = ICONS[weather.icon.replace('n', 'd')]
  const alt = weather.description
  return <Icon key={alt} src={icon} alt={alt} />
}

export default class WeatherWidget extends React.Component {
  static propTypes = {
    city: PropTypes.string,
    weather: PropTypes.array,
    plain: PropTypes.bool,
  }

  static defaultProps = {
    city: null,
    weather: null,
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { weather = {}, plain } = this.props
    const localWeather = _.find(weather, { name: this.props.city })
    return (
      <Container visible={!!localWeather} plain={plain}>
        <span>
          {!!localWeather && !plain && mapWeather(localWeather.weather[0])}
          {!!localWeather && Math.round(localWeather.main.temp)}&deg;
        </span>
      </Container>
    )
  }
}
